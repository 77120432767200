import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/new/home/index.vue'
import Chat from '../views/chat/Chat.vue'
import Send from '../views/chat/Send.vue'
import Bottom from '../views/new/bottom/index.vue'
import News from '../views/new/news/index.vue'
import Job from '../views/new/job/index.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/chat',
    name: 'chat',
    component: Chat
  },
  {
    path: '/send',
    name: 'send',
    component: Send
  },
  {
    path: '/bottom',
    name: 'bottom',
    component: Bottom
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/about',
    name: 'job',
    component: Job
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/front/AboutView.vue')
  },
  {
    path:'/mail',
    name:'mail',
    component: () => import(/* webpackChunkName: "about" */ '../views/new/mail/index.vue')
  },
  {
    path:'/form',
    name:'form',
    component: () => import(/* webpackChunkName: "about" */ '../views/form/index.vue')
  }
]

const  scrollBehavior = (to, from, savedPosition) => {

  if (savedPosition && to.meta.keepAlive) {
    return savedPosition;
  }
  return { left: 0};
}

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior
})



export default router
