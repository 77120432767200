<template>
  <div class="page flex-col">
    <div v-if="showSubmit" class="toastBackground">
      <div class="shiyongbiaodan">
        <div class="yuyueyanshi">
          预约演示申请
          <img
            @click="closeForm"
            class="icon_guanbitanchuang"
            src="./assets/img/icon_guanbitanchuang.png"
          />
        </div>
        <el-form
          :model="dynamicValidateForm"
          label-width="200px"
          class="demo-dynamic"
          ref="dynamicValidateFormRef"
        >
          <el-form-item
            prop="name"
            label="您的名字(已加密)："
            :rules="[
              { required: true, message: '请输入名字', trigger: 'blur' }
            ]"
          >
            <el-input v-model="dynamicValidateForm.name"></el-input>
          </el-form-item>
          <el-form-item
            prop="company_name"
            label="您的公司全称(已加密)："
            :rules="[
              { required: true, message: '请输入公司全称', trigger: 'blur' }
            ]"
          >
            <el-input v-model="dynamicValidateForm.company_name"></el-input>
          </el-form-item>
          <el-form-item
            prop="email"
            label="邮箱(已加密)："
            :rules="[
              { required: false, message: '请输入邮箱', trigger: 'blur' }
            ]"
          >
            <el-input v-model="dynamicValidateForm.email"></el-input>
          </el-form-item>
          <el-form-item
            prop="phone"
            label="手机号码(已加密)："
            :rules="[
              { required: true, message: '请输入手机号码', trigger: 'blur' },
              { min: 11, max: 11, message: '长度必须是11位', trigger: 'blur' }
            ]"
          >
            <el-input v-model="dynamicValidateForm.phone"></el-input>
          </el-form-item>
          <el-form-item
            prop="code"
            label="短信验证码："
            :rules="[
              { required: true, message: '请输入短信验证码', trigger: 'blur' }
            ]"
          >
            <el-input
              style="width: 150px"
              v-model="dynamicValidateForm.code"
            ></el-input>
            <el-button
              class="el_button_class"
              style="margin-left: 10px"
              size="small"
              type="primary"
              @click="sendCode"
              >{{ showCode }}</el-button
            >
          </el-form-item>
          <div v-if="phoneError" style="margin-left: 193px; color: red">
            手机号或验证码错误
          </div>
          <el-form-item>
            <el-button
              style="margin-top: 10px"
              class="el_button_class"
              type="primary"
              @click="submitForm('dynamicValidateFormRef')"
              >立即申请</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-if="showSubmitSuccess" class="toastBackground">
      <div class="showSubmitSuccess">
        <span class="success_message">我们已收到您的申请，我们会尽快联系您。再次感谢您的关注。</span><br />
        <el-button
              style="margin-top: 20px"
              class="el_button_class"
              type="primary"
              @click="submitshowSubmitSuccess"
              >确定</el-button
            >
      </div>
    </div>
    <ClickUser v-if="clickUserShow" v-on:success="showyuyueForm1" />
    <div class="box_1 flex-col">
      <div class="group_1 flex-row">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/logo_bai.png"
        />
        <div class="text-wrapper_1 flex-col">
          <span style="cursor: pointer" class="text_1">巧鲸云IP副驾驶</span>
        </div>
        <span style="cursor: pointer;width: 2.9rem;" class="text_2" ><router-link to="/mail">巧鲸云邮件分发助手</router-link></span>
        <span style="cursor: pointer;margin: 0.614rem 0 0 2.44rem" class="text_2" @click="newsMore">新闻动态</span>
        <span style="cursor: pointer;margin: 0.614rem 0 0 1rem" class="text_2"><router-link to="/about">关于我们</router-link></span>
        <span style="cursor: pointer;margin: 0.614rem 0 0 1.7rem" @click="showyuyueForm1" class="text_3"
        
          >点击试用</span
        >
      </div>
      <div class="group_2 ">
        <div class="text-qiaojingyun">
          <p  class="text_4">巧鲸云 IP副驾驶</p>
          <p  class="text_4"> 致力于打造数智化商标档案管理系统</p>
          <p class="text_3">
            <span> <img src="../../new/home/assets/img/icon_gouxuan.png" alt=""> 品牌管理</span>
            <span> <img src="../../new/home/assets/img/icon_gouxuan.png" alt="">商标管理</span>
            <span><img src="../../new/home/assets/img/icon_gouxuan.png" alt="">档案管理</span>
          </p>
        </div>
        <div @click="showyuyueForm" class="text-wrapper_2 flex-col">
          <span class="text_4">预约演示申请</span>
        </div>
      </div>
    </div>
    <div class="box_2 flex-col">
      <div class="text-wrapper_3 flex-row">
        <span class="text_5">传统档案管理方式</span>
      </div>
      <div class="box_3 flex-row">
        <div class="image-text_1 flex-row justify-between">
          <img
            class="label_1"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng21bcc6a44024835a5ca814ad8e5640668fb3cd1891430dfbb064dd3d9b0abc27.png"
          />
          <span class="text-group_1">数据易错漏</span>
        </div>
        <img
          class="label_2"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng563e1db3c85d85aab79e6e253428dbb19df690b0394b220a5833075e86e3af6c.png"
        />
        <span class="text_6">商标状态无法实时更新</span>
      </div>
      <div class="box_4 flex-row">
        <div class="box_5 flex-col">
          <span class="text_7"
            >使用Excel表格+本地文件存档，无法实时信息同步，人员变动易错漏。</span
          >
          <div class="box_6 flex-row justify-between">
            <div class="image-text_2 flex-row justify-between">
              <img
                class="label_3"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngc72a31c47d5b1d35ab33b172e3d022dc9b04d8be5a47d8f232d93776812e7a22.png"
              />
              <span class="text-group_2">数据输出不灵活</span>
            </div>
            <div class="box_7 flex-col"></div>
          </div>
          <div class="box_8 flex-row justify-between">
            <img
              class="label_4"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng80c1889129b94fde2f2e5cf93ea9ae1f53fd5e98a770efa14faa1d56ebeff15e.png"
            />
            <span class="text_8">工作效率低</span>
          </div>
          <span class="text_9"
            >商标数量及各类官文档案量大且庞杂，手工存档或上传系统，效率低。</span
          >
        </div>
        <div class="box_9 flex-col">
          <div class="section_1 flex-col"></div>
          <div class="section_2 flex-col"></div>
        </div>
        <div class="box_10 flex-col">
          <span class="text_10">获取官文后无法实时同步至商标管理台账。</span>
          <div class="block_1 flex-row justify-between">
            <img
              class="label_5"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng4d7e388d0be3b9d0c3badaba23b026f65635865a035887345f1d11f645651bf3.png"
            />
            <span class="text_11">品牌管理视觉缺失</span>
          </div>
          <span class="text_12"
            >无法直接将企业品牌发展战略与大量商标数据对应关联，掌控品牌保护现状。</span
          >
          <div class="block_2 flex-row justify-between">
            <img
              class="label_6"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngeaedbe6b1424b80c2a22255b8760fc9decc8936880ff2c83c51d0416fa421899.png"
            />
            <span class="text_13">数据统计工作繁冗</span>
          </div>
          <span class="text_14"
            >海量商标数据，无法一键立体完成数据需求展示。</span
          >
        </div>
      </div>
      <span class="text_15"
        >业务需求调取多件商标多类文件，逐一翻找文件夹，费时费力。</span
      >
    </div>
    <div v-if="showYouShi === 8" class="box_781 flex-col">
      <span class="text_16">我们的优势</span>
      <span class="text_17">
        “巧鲸云IP副驾驶”是北京万合之众科技有限公司自主研发的一套数智化商标档案管理系统，通过OCR智能识别技术，推动整个管理系统的进行，对品牌、商标、档案进行安全高效管理，致力于为企业打造传输、管理、数据分析于一体的闭环管理模式。
      </span>
      <div class="text-wrapper_4 flex-row justify-between">
        <span style="cursor: pointer" @click="youshiClick(1)" class="text_18"
          >品牌管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(2)" class="text_19"
          >商标管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(3)" class="text_20"
          >档案管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(4)" class="text_21"
          >案件管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(5)" class="text_22"
          >版权域名管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(6)" class="text_23"
          >证据中心</span
        >
        <span style="cursor: pointer" @click="youshiClick(7)" class="text_24"
          >费用管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(8)" class="text_25"
          >可视化分析</span
        >
      </div>
      <div class="image-wrapper_1 flex-col">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd80b20fdc008a473c6125aacc3c19608afff91772038bd4a3bdd2626acf6e4fe.png"
        />
      </div>
      <div class="box_8 flex-row justify-between">
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng12e6441b2bf682a40d22d433f5f8530e4e4c4ad8c5647eeae2f4b6581c50961e.png"
        />
        <div class="group_8 flex-col justify-between">
          <div class="section_4 flex-row">
            <span class="text_22">可视化分析</span>
            <div class="text-wrapper_5 flex-col">
              <span class="text_23">数据报告</span>
            </div>
            <div class="text-wrapper_6 flex-col">
              <span class="text_24">多维度</span>
            </div>
          </div>
          <div class="section_5 flex-row">
            <div class="image-text_3 flex-row justify-between">
              <img
                class="image_5"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
              />
            </div>
            <div class="image-text_4 flex-row justify-between">
              <img
                class="image_6"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
              />
              <div class="text-group_4 flex-col justify-between">
                <span class="text_25" style="width: 16.8rem">站在品牌角度，支持商标、案件、时间、费用等多筛选条件下的可视化数据展示</span>
                <span class="text_26"
                  >支持可视化图表导出，便利统计和汇报</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showYouShi === 7" class="box_780 flex-col">
      <span class="text_16">我们的优势</span>
      <span class="text_17">
        “巧鲸云IP副驾驶”是北京万合之众科技有限公司自主研发的一套数智化商标档案管理系统，通过OCR智能识别技术，推动整个管理系统的进行，对品牌、商标、档案进行安全高效管理，致力于为企业打造传输、管理、数据分析于一体的闭环管理模式。
      </span>
      <div class="text-wrapper_4 flex-row justify-between">
        <span style="cursor: pointer" @click="youshiClick(1)" class="text_18"
          >品牌管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(2)" class="text_19"
          >商标管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(3)" class="text_20"
          >档案管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(4)" class="text_21"
          >案件管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(5)" class="text_22"
          >版权域名管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(6)" class="text_23"
          >证据中心</span
        >
        <span style="cursor: pointer" @click="youshiClick(7)" class="text_24"
          >费用管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(8)" class="text_25"
          >可视化分析</span
        >
      </div>
      <div class="image-wrapper_1 flex-col">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd80b20fdc008a473c6125aacc3c19608afff91772038bd4a3bdd2626acf6e4fe.png"
        />
      </div>
      <div class="box_8 flex-row justify-between">
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng41ede2511215d13a4d7bd5742fa39989417b689132bb360f0b528143cfd17e00.png"
        />
        <div class="group_8 flex-col justify-between">
          <div class="section_4 flex-row">
            <span class="text_22">费用管理</span>
            <!-- <div class="text-wrapper_5 flex-col">
              <span class="text_23">数据报告</span>
            </div>
            <div class="text-wrapper_6 flex-col">
              <span class="text_24">多维度</span>
            </div> -->
          </div>
          <div class="section_5 flex-row">
            <div class="image-text_3 flex-row justify-between">
              <img
                class="image_5"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
              />
            </div>
            <div class="image-text_4 flex-row justify-between">
              <img
                class="image_6"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
              />
              <div class="text-group_4 flex-col justify-between">
                <span class="text_25" >根据不同合作方的费用标准自动匹配对应案件</span>
                <span class="text_26"
                  >支持多维度数据分析，全方位展示案件费用支出情况</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div v-if="showYouShi === 6" class="box_779 flex-col">
      <span class="text_16">我们的优势</span>
      <span class="text_17">
        “巧鲸云IP副驾驶”是北京万合之众科技有限公司自主研发的一套数智化商标档案管理系统，通过OCR智能识别技术，推动整个管理系统的进行，对品牌、商标、档案进行安全高效管理，致力于为企业打造传输、管理、数据分析于一体的闭环管理模式。
      </span>
      <div class="text-wrapper_4 flex-row justify-between">
        <span style="cursor: pointer" @click="youshiClick(1)" class="text_18"
          >品牌管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(2)" class="text_19"
          >商标管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(3)" class="text_20"
          >档案管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(4)" class="text_21"
          >案件管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(5)" class="text_22"
          >版权域名管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(6)" class="text_23"
          >证据中心</span
        >
        <span style="cursor: pointer" @click="youshiClick(7)" class="text_24"
          >费用管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(8)" class="text_25"
          >可视化分析</span
        >
      </div>
      <div class="image-wrapper_1 flex-col">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd80b20fdc008a473c6125aacc3c19608afff91772038bd4a3bdd2626acf6e4fe.png"
        />
      </div>
      <div class="box_8 flex-row justify-between">
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng41ede2511215d13a4d7bd5742fa39989417b689132bb360f0b528143cfd17e00.png"
        />
        <div class="group_8 flex-col justify-between">
          <div class="section_4 flex-row">
            <span class="text_22">证据中心</span>
            <!-- <div class="text-wrapper_5 flex-col">
              <span class="text_23">数据报告</span>
            </div>
            <div class="text-wrapper_6 flex-col">
              <span class="text_24">多维度</span>
            </div> -->
          </div>
          <div class="image-text_6 flex-row justify-between">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_6">共有证据和个案证据分类管理</span>
          </div>
          <div class="image-text_6 flex-row justify-between">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_6">域名续费等期限类提醒</span>
          </div>
          <div class="image-text_6 flex-row justify-between">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_6">品牌与版权授权信息管理</span>
          </div>
        </div>
      </div>
    </div>
      <div v-if="showYouShi === 5" class="box_778 flex-col">
      <span class="text_16">我们的优势</span>
      <span class="text_17">
        “巧鲸云IP副驾驶”是北京万合之众科技有限公司自主研发的一套数智化商标档案管理系统，通过OCR智能识别技术，推动整个管理系统的进行，对品牌、商标、档案进行安全高效管理，致力于为企业打造传输、管理、数据分析于一体的闭环管理模式。
      </span>
      <div class="text-wrapper_4 flex-row justify-between">
        <span style="cursor: pointer" @click="youshiClick(1)" class="text_18"
          >品牌管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(2)" class="text_19"
          >商标管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(3)" class="text_20"
          >档案管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(4)" class="text_21"
          >案件管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(5)" class="text_22"
          >版权域名管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(6)" class="text_23"
          >证据中心</span
        >
        <span style="cursor: pointer" @click="youshiClick(7)" class="text_24"
          >费用管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(8)" class="text_25"
          >可视化分析</span
        >
      </div>
      <div class="image-wrapper_1 flex-col">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd80b20fdc008a473c6125aacc3c19608afff91772038bd4a3bdd2626acf6e4fe.png"
        />
      </div>
      <div class="box_8 flex-row justify-between">
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng41ede2511215d13a4d7bd5742fa39989417b689132bb360f0b528143cfd17e00.png"
        />
        <div class="group_8 flex-col justify-between">
          <div class="section_4 flex-row">
            <span class="text_22">版权域名管理</span>
            <!-- <div class="text-wrapper_5 flex-col">
              <span class="text_23">数据报告</span>
            </div>
            <div class="text-wrapper_6 flex-col">
              <span class="text_24">多维度</span>
            </div> -->
          </div>
          <div class="image-text_6 flex-row justify-between">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_6">作品档案管理</span>
          </div>
          <div class="image-text_6 flex-row justify-between">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_6">计算机软件档案管理</span>
          </div>
          <div class="image-text_6 flex-row justify-between">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_6">域名管理</span>
          </div>
          <div class="image-text_6 flex-row justify-between">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_6">从品牌维度对商标证据进行标签化管理</span>
          </div>
          <div class="image-text_6 flex-row justify-between">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_6">一键生成下载链接，自定义设置下载密码和期限</span>
          </div>
        </div>
      </div>

    </div>
    <div v-if="showYouShi === 4" class="box_777 flex-col">
      <span class="text_16">我们的优势</span>
      <span class="text_17">
        “巧鲸云IP副驾驶”是北京万合之众科技有限公司自主研发的一套数智化商标档案管理系统，通过OCR智能识别技术，推动整个管理系统的进行，对品牌、商标、档案进行安全高效管理，致力于为企业打造传输、管理、数据分析于一体的闭环管理模式。
      </span>
      <div class="text-wrapper_4 flex-row justify-between">
        <span style="cursor: pointer" @click="youshiClick(1)" class="text_18"
          >品牌管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(2)" class="text_19"
          >商标管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(3)" class="text_20"
          >档案管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(4)" class="text_21"
          >案件管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(5)" class="text_22"
          >版权域名管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(6)" class="text_23"
          >证据中心</span
        >
        <span style="cursor: pointer" @click="youshiClick(7)" class="text_24"
          >费用管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(8)" class="text_25"
          >可视化分析</span
        >
      </div>
      <div class="image-wrapper_1 flex-col">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd80b20fdc008a473c6125aacc3c19608afff91772038bd4a3bdd2626acf6e4fe.png"
        />
      </div>
      <div class="box_8 flex-row justify-between">
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng41ede2511215d13a4d7bd5742fa39989417b689132bb360f0b528143cfd17e00.png"
        />
        <div class="group_8 flex-col justify-between">
          <div class="section_4 flex-row">
            <span class="text_22">案件管理</span>
            <!-- <div class="text-wrapper_5 flex-col">
              <span class="text_23">数据报告</span>
            </div>
            <div class="text-wrapper_6 flex-col">
              <span class="text_24">多维度</span>
            </div> -->
          </div>
          <div class="section_5 flex-row">
            <div class="image-text_3 flex-row justify-between">
              <img
                class="image_5"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
              />
            </div>
            <div class="image-text_4 flex-row justify-between">
              <img
                class="image_6"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
              />
              <div class="text-group_4 flex-col justify-between">
                <span class="text_25" >智能语义分析官文，识别判定案件结果</span>
                <span class="text_26" style="width: 16rem"
                  >支持通过案件类型、案件结果、主体信息等多字段检索目标案件</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showYouShi === 3" class="group_888 flex-col">
      <span class="text_16">我们的优势</span>
      <span class="text_17">
        “巧鲸云IP副驾驶”是北京万合之众科技有限公司自主研发的一套数智化商标档案管理系统，通过OCR智能识别技术，推动整个管理系统的进行，对品牌、商标、档案进行安全高效管理，致力于为企业打造传输、管理、数据分析于一体的闭环管理模式。
      </span>
      <div class="text-wrapper_4 flex-row justify-between">
        <span style="cursor: pointer" @click="youshiClick(1)" class="text_18"
          >品牌管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(2)" class="text_19"
          >商标管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(3)" class="text_20"
          >档案管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(4)" class="text_21"
          >案件管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(5)" class="text_22"
          >版权域名管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(6)" class="text_23"
          >证据中心</span
        >
        <span style="cursor: pointer" @click="youshiClick(7)" class="text_24"
          >费用管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(8)" class="text_25"
          >可视化分析</span
        >
      </div>
      <div class="image-wrapper_1 flex-col">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd80b20fdc008a473c6125aacc3c19608afff91772038bd4a3bdd2626acf6e4fe.png"
        />
      </div>
      <div class="section_1 flex-row justify-between">
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngafe9c5fb749f0885c7121e0d2f2f401a5b7e0bcb142ca4497845abc6901f5b7d.png"
        />
        <div class="box_6 flex-col">
          <div class="box_7 flex-row">
            <span class="text_22">档案管理</span>
            <div class="text-wrapper_5 flex-col">
              <span class="text_23">智能筛选</span>
            </div>
            <div class="text-wrapper_6 flex-col">
              <span class="text_24">时限预警</span>
            </div>
          </div>
          <div class="image-text_3 flex-row justify-between">
            <img
              class="image_5"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_3">初始化系统，无需建表，一键导入申请人商标信息</span>
          </div>
          <div class="image-text_4 flex-row justify-between">
            <img
              class="image_6"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_4">智能语义分析识别官文，自动归档</span>
          </div>
          <div class="image-text_5 flex-row justify-between">
            <img
              class="image_7"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_5">支持邮箱抄送导入官文，系统自动提醒用户档案更新</span>
          </div>
          <div class="image-text_6 flex-row justify-between">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_6">支持自定义设置字段，一键导出商标Excel表格及相应官文</span>
          </div>
          <!-- <div class="image-text_7 flex-row justify-between">
            <img
              class="image_9"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_7">自定义设置阶段性期限提醒</span>
          </div> -->
        </div>
      </div>
    </div>
    <div v-if="showYouShi === 2" class="block_333 flex-col">
      <span class="text_16">我们的优势</span>
      <span class="text_17">
        “巧鲸云IP副驾驶”是北京万合之众科技有限公司自主研发的一套数智化商标档案管理系统，通过OCR智能识别技术，推动整个管理系统的进行，对品牌、商标、档案进行安全高效管理，致力于为企业打造传输、管理、数据分析于一体的闭环管理模式。
      </span>
      <div class="text-wrapper_4 flex-row justify-between">
        <span style="cursor: pointer" @click="youshiClick(1)" class="text_18"
          >品牌管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(2)" class="text_19"
          >商标管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(3)" class="text_20"
          >档案管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(4)" class="text_21"
          >案件管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(5)" class="text_22"
          >版权域名管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(6)" class="text_23"
          >证据中心</span
        >
        <span style="cursor: pointer" @click="youshiClick(7)" class="text_24"
          >费用管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(8)" class="text_25"
          >可视化分析</span
        >
      </div>
      <div class="image-wrapper_1 flex-col">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd80b20fdc008a473c6125aacc3c19608afff91772038bd4a3bdd2626acf6e4fe.png"
        />
      </div>
      <div class="group_8 flex-row justify-between">
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng41ede2511215d13a4d7bd5742fa39989417b689132bb360f0b528143cfd17e00.png"
        />
        <div class="group_9 flex-col justify-between">
          <div class="section_6 flex-row">
            <span class="text_22">商标管理</span>
            <div class="text-wrapper_5 flex-col">
              <span class="text_23">状态更新</span>
            </div>
            <div class="text-wrapper_6 flex-col">
              <span class="text_24">案件关联</span>
            </div>
          </div>
          <div class="section_7 flex-row">
            <div class="image-text_3 flex-row justify-between">
              <img
                class="image_5"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
              />
            </div>
            <div class="image-text_4 flex-row justify-between">
              <img
                class="image_6"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
              />
            </div>
            <div class="image-text_5 flex-row justify-between">
              <img
                class="image_7"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
              />
              <div class="text-group_5 flex-col">
                <span class="text_25"
                  >智能语义分析识别官文，触发商标状态等信息自动更新</span
                >
                <span class="text_26">官文上传后自动生成商标关联图，一图秒懂己方和关联案件生命周期</span>
                <span class="text_27">多类型商标预警，为商标数据安全保驾护航</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showYouShi === 1" class="box_11 flex-col">
      <span class="text_16">我们的优势</span>
      <span class="text_17">
        “巧鲸云IP副驾驶”是北京万合之众科技有限公司自主研发的一套数智化商标档案管理系统，通过OCR智能识别技术，推动整个管理系统的进行，对品牌、商标、档案进行安全高效管理，致力于为企业打造传输、管理、数据分析于一体的闭环管理模式。
      </span>
      <div class="text-wrapper_4 flex-row justify-between">
        <span style="cursor: pointer" @click="youshiClick(1)" class="text_18"
          >品牌管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(2)" class="text_19"
          >商标管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(3)" class="text_20"
          >档案管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(4)" class="text_21"
          >案件管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(5)" class="text_22"
          >版权域名管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(6)" class="text_23"
          >证据中心</span
        >
        <span style="cursor: pointer" @click="youshiClick(7)" class="text_24"
          >费用管理</span
        >
        <span style="cursor: pointer" @click="youshiClick(8)" class="text_25"
          >可视化分析</span
        >
      </div>
      <div class="image-wrapper_1 flex-col">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngd80b20fdc008a473c6125aacc3c19608afff91772038bd4a3bdd2626acf6e4fe.png"
        />
      </div>
      <div class="box_12 flex-row justify-between">
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng94fc2abb91a712aaa035c52f107c003ffaa498f5b8b660f4c17159f8e18392ef.png"
        />
        <div class="block_3 flex-col">
          <div class="group_3 flex-row">
            <span class="text_22">品牌管理</span>
            <div class="text-wrapper_5 flex-col">
              <span class="text_23">自定义</span>
            </div>
            <div class="text-wrapper_6 flex-col">
              <span class="text_24">业务线调整</span>
            </div>
          </div>
          <div class="image-text_3 flex-row justify-between">
            <img
              class="image_5"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_3">自定义品牌关键词</span>
          </div>
          <div class="image-text_4 flex-row justify-between">
            <img
              class="image_6"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_4">海量商标数据自动关联</span>
          </div>
          <div class="image-text_5 flex-row justify-between">
            <img
              class="image_7"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_5">一键生成品牌树</span>
          </div>
          <div class="image-text_6 flex-row justify-between">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_6">商标布局情况一目了然</span>
          </div>
          <div class="image-text_6 flex-row justify-between">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd612d7926da759088e6d151ff39d90754cf4500605793ad8e310173c8cb247b1.png"
            />
            <span class="text-group_6">以产品为关键词快速检索需求商标</span>
          </div>
        </div>
      </div>
    </div>
    <div class="box_13 flex-col">
      <span class="text_25">功能介绍</span>
      <div class="box_14 flex-col">
        <!-- muted="false" -->
        <video
          preload="auto"
          autoplay="true"
          webkit-playsinline="true"
          playsinline="true"
          x-webkit-airplay="allow"
          x5-video-player-type="h5"
          x5-video-orientation="portraint"
          style="object-fit: cover"
          class="video_s"
          controls="true"
        >
          <source
            src="/static1/video1.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
    <!-- #fafcff -->
    <div class="box_16 flex-col">
      <p  class="text_25">部分合作品牌</p>
      <div class="image-container" style="display:flex;flex-wrap:wrap;margin-top:0.6rem;padding:0 2.8rem">
        <div v-for="(v,i) in brandLogArr" :key="i" class="image-row" style="width:25%;height:3.5rem;margin-bottom:1.5625rem">
          <img :src="v" alt="" style="width:80%;height:3.5rem">
        </div>
      </div>
      <div style="position: absolute; top: 19.39rem">
        <Bottom />
      </div>
    </div>
  </div>
</template>
<script>
import Chat from '../../chat/Chat.vue'
import Bottom from '../bottom/index.vue'
import { ref, onMounted, onBeforeMount } from 'vue'
import { post, get } from '@/utils/request'
import ClickUser from '@/components/ClickUser.vue'
import { useRouter, useRoute } from 'vue-router'
import { ElMessageBox } from 'element-plus'
export default {
  components: { Chat, Bottom, ClickUser },
  setup(props) {
    onBeforeMount(async () => {
      setInterval(() => {
        if(showYouShi.value < 8){
          showYouShi.value = showYouShi.value + 1
        }else{
          showYouShi.value = 1
        }
      }, 5000);
      await get('/admin/view/add', {
        addOne: 'home_view'
      })
    })
    onMounted(async () => {
      const homeList = await get('/admin/news/homeList', {})
      if (homeList.code === 0) {
        loopData0.value = homeList.data
      }
    })
    const router = useRouter()
    const route = useRoute()
    const showSubmit = ref(false)
    const constants = {}
    const showYouShi = ref(1)
    const showZaixiankefu = ref(false)
    const showSubmitSuccess = ref(false)
    const showCode = ref('短信验证码')
    const dynamicValidateFormRef = ref(null)
    const phoneError = ref(false)
    const companyJobShow = ref(false)
    const clickUserShow = ref(false)
    const dynamicValidateForm = ref({
      name: undefined,
      company_name: undefined,
      company_job: undefined,
      phone: undefined,
      email: undefined,
      code: undefined
    })
    const brandLogArr = [
      require ('../../../assets/brandLog/1.png'),
      require ('../../../assets/brandLog/2.png'),
      require ('../../../assets/brandLog/3.png'),
      require ('../../../assets/brandLog/4.png'),
      require ('../../../assets/brandLog/5.png'),
      require ('../../../assets/brandLog/6.png'),
      require ('../../../assets/brandLog/7.png'),
      require ('../../../assets/brandLog/9.png'),
      require ('../../../assets/brandLog/8.png'),
      require ('../../../assets/brandLog/10.png'),
      require ('../../../assets/brandLog/11.png'),
      require ('../../../assets/brandLog/12.png'),
    ];
    const loopData0 = ref([
      // {
      //   img:
      //     'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/a1c5325b417e42e6ab7c7c365b549198_mergeImage.png)',
      //   title: '招商基金',
      //   content:
      //     '作为中国知名的基金管理公司，招商基金拥有庞大的产品体系和用户群体，公司目前管理着…'
      // }
    ])
    const youshiClick = (msg) => {
      showYouShi.value = msg
    }
    const toTop = () => {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
    const showkefu = () => {
      if (showZaixiankefu.value) {
        showZaixiankefu.value = false
      } else {
        showZaixiankefu.value = true
      }
    }
    const closeZai = () => {
      showZaixiankefu.value = false
    }
    const submitForm = (formName) => {
      dynamicValidateFormRef.value.validate(async (valid) => {
        if (valid) {
          const resData = await post('/apply/form', {
            phone: dynamicValidateForm.value.phone,
            name: dynamicValidateForm.value.name,
            companyName: dynamicValidateForm.value.company_name,
            companyJob: dynamicValidateForm.value.company_job,
            email: dynamicValidateForm.value.email,
            code: dynamicValidateForm.value.code,
            type: 'Y'
          })
          if (resData.code != 0) {
            phoneError.value = true
          } else {
            showSubmitSuccess.value = true
            showSubmit.value = false
            dynamicValidateForm.value.phone = undefined
            dynamicValidateForm.value.name = undefined
            dynamicValidateForm.value.company_name = undefined
            dynamicValidateForm.value.company_job = undefined
            dynamicValidateForm.value.email = undefined
            dynamicValidateForm.value.code = undefined
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
    const closeForm = () => {
      dynamicValidateForm.value.phone = undefined
      dynamicValidateForm.value.name = undefined
      dynamicValidateForm.value.company_name = undefined
      dynamicValidateForm.value.company_job = undefined
      dynamicValidateForm.value.email = undefined
      dynamicValidateForm.value.code = undefined
      showSubmit.value = false
      phoneError.value = false
    }
    const submitshowSubmitSuccess = ()=>{
      showSubmitSuccess.value = false
    }
    const resetForm = (formName) => {
      this.$refs[formName].resetFields()
    }
    const showyuyueForm = () => {
      showSubmit.value = true
      phoneError.value = false
    }
    const removeDomain = (item) => {
      var index = this.dynamicValidateForm.domains.indexOf(item)
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1)
      }
    }
    const addDomain = () => {
      this.dynamicValidateForm.domains.push({
        value: '',
        key: Date.now()
      })
    }
    const checknumber = (String) => {
      var reg = /^\d+$/
      if (reg.test(String)) {
        return true
      }
      return false
    }
    const showyuyueForm1 = () => {
      if (clickUserShow.value === true) {
        clickUserShow.value = false
      } else {
        clickUserShow.value = true
      }
    }
    const sendCode = async () => {
      if (checknumber(dynamicValidateForm.value.phone) != true) {
        return
      }
      if(dynamicValidateForm.value.phone.length != 11){
        return
      }
      if (showCode.value != '短信验证码') {
        return
      }
      let i = 60
      const interval = setInterval(() => {
        showCode.value = i + 's'
        i = i - 1
        if (i == 0) {
          showCode.value = '短信验证码'
          clearInterval(interval)
        }
      }, 1000)
      const resData = await get('/apply/send', {
        phone: dynamicValidateForm.value.phone
      })
      if (resData.code === 0) {
      }
    }
    const toNews = async(item) => {
      await get('/admin/view/add', {
        addOne: 'news_view'
      })
      router.push(`/news?item=1`)
      localStorage.setItem('item', JSON.stringify(item))
    }
    const newsMore = () => {
      router.push(`/news?item=1`)
      localStorage.setItem('item', JSON.stringify(loopData0.value[0]))
    }
    return {
      loopData0,
      showYouShi,
      constants,
      youshiClick,
      toTop,
      showkefu,
      showZaixiankefu,
      closeZai,
      dynamicValidateForm,
      submitForm,
      resetForm,
      removeDomain,
      addDomain,
      showCode,
      sendCode,
      dynamicValidateFormRef,
      showSubmit,
      phoneError,
      companyJobShow,
      closeForm,
      showyuyueForm,
      clickUserShow,
      showyuyueForm1,
      toNews,
      newsMore,
      showSubmitSuccess,
      submitshowSubmitSuccess,
      brandLogArr
    }
  }
}
</script>
<style scoped lang="scss" src="./assets/index.rem.scss" />