<template>
  <div class="hello">
    <Send ref="send" :fun="fun" />
    <div @click="openOrClose(false)" class="chat_content">
      <div class="top">
        <div class="content">在线客服</div>
        <img
          class="x"
          @click="closeZaiXian"
          src="../new/home/assets/img/icon_guanbitanchuang_bai.png"
        />
      </div>
      <div class="content" id="dialogue_box">
        <div
          class="row"
          v-for="(row, index) in messageList"
          :key="index"
          :id="'msg' + row.msg.id"
        >
          <!-- 自己发出的消息 -->
          <div class="my" v-if="row.msg.userinfo.uid == 0">
            <!-- 左-消息 -->
            <div class="left">
              <!-- 文字消息 -->
              <div v-if="row.msg.type == 'text'" class="bubble">
                <div class="my_span" v-html="row.msg.content.text"></div>
              </div>
              <!-- 图片消息 -->
              <div v-if="row.msg.type == 'img'" class="left_img1">
                <a :href="row.msg.content.url" target="_blank">
                  <img
                    :src="row.msg.content.url"
                    :style="{
                      width: row.msg.content.w + 'px',
                      height: row.msg.content.h + 'px'
                    }"
                  />
                </a>
              </div>
            </div>
          </div>
          <!-- 别人发出的消息 -->
          <div class="other" v-if="row.msg.userinfo.uid != 0">
            <!-- 左-头像 -->
            <div class="left">
              <img
                class="left_img"
                src="../new/home/assets/img/img_touxiang_kefu.png"
              />
            </div>
            <!-- 右-用户名称-时间-消息 -->
            <div class="right">
              <!-- 文字消息 -->
              <div v-if="row.msg.type == 'text'" class="bubble">
                <div class="my_span" v-html="row.msg.content.text"></div>
              </div>
              <!-- 图片消息 -->
              <div
                v-if="row.msg.type == 'img'"
                @click="showPic(row.msg)"
                style="margin-left: 2.3rem; margin-top: -1.4rem"
              >
                <a :href="row.msg.content.url" target="_blank">
                  <img
                    :src="row.msg.content.url"
                    :style="{
                      width: row.msg.content.w + 'px',
                      height: row.msg.content.h + 'px'
                    }"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <input
          class="input_class"
          id="inputbox"
          v-model="sendMsgContent"
          @keyup.enter="onSubmit('text')"
          placeholder="请输入信息，按Enter键直接发送"
        />
        <img
          @click.stop="openOrClose(true)"
          class="biaoqing"
          src="@/assets/chat/biaoqing.png"
        />
        <el-upload
          ref="upload"
          class="upload-demo"
          :action="uploadUrl"
          :limit="1"
          accept=".png, .jpg"
          :auto-upload="true"
          :on-success="handleSuccess"
          :on-error="handleFale"
        >
          <template #trigger>
            <img
              class="tupian"
              @click="uploadImg"
              src="@/assets/chat/tupian.png"
            />
          </template>
        </el-upload>
        <div class="fasong" @click="onSubmit('text')">
          <img
            class="fasong_img"
            src="../new/home/assets/img/icon_fasong.png"
          />
          <span class="fasong_wenzi">发送</span>
        </div>
      </div>
      <div v-if="emjonShow" class="emjon" @click.stop="">
        <div class="emjon_class">
          <div v-for="(em, eid) in emojiList" :key="eid" @click="addEmoji(em)">
            <img :src="em.url" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Send from './Send.vue'
import { ref } from 'vue'
import { onMounted, onUpdated, onUnmounted } from 'vue'
import { genFileId } from 'element-plus'
import { UploadInstance, UploadProps, UploadRawFile } from 'element-plus'
import { baseUrl, basews } from '@/utils/request'
export default {
  components: { Send },
  name: 'HelloWorld',
  props: {
    msg: String,
    closeZai: Function
  },
  setup(props) {
    const closeZaiXian = ()=>{
      props.closeZai()
    }
    const uploadUrl = baseUrl + '/chat/upload/'
    const upload = ref(null)
    const emojiList = [
      {
        url: require('@/assets/em/100.gif'),
        alt: '[微笑]',
        name: '100.gif'
      },
      {
        url: require('@/assets/em/101.gif'),
        alt: '[伤心]',
        name: '101.gif'
      },
      {
        url: require('@/assets/em/102.gif'),
        alt: '[美女]',
        name: '102.gif'
      },
      {
        url: require('@/assets/em/103.gif'),
        alt: '[发呆]',
        name: '103.gif'
      },
      {
        url: require('@/assets/em/104.gif'),
        alt: '[墨镜]',
        name: '104.gif'
      },
      {
        url: require('@/assets/em/105.gif'),
        alt: '[哭]',
        name: '105.gif'
      },
      {
        url: require('@/assets/em/106.gif'),
        alt: '[羞]',
        name: '106.gif'
      },
      {
        url: require('@/assets/em/107.gif'),
        alt: '[哑]',
        name: '107.gif'
      },
      {
        url: require('@/assets/em/108.gif'),
        alt: '[睡]',
        name: '108.gif'
      },
      {
        url: require('@/assets/em/109.gif'),
        alt: '[哭]',
        name: '109.gif'
      },
      {
        url: require('@/assets/em/110.gif'),
        alt: '[囧]',
        name: '110.gif'
      },
      {
        url: require('@/assets/em/111.gif'),
        alt: '[怒]',
        name: '111.gif'
      },
      {
        url: require('@/assets/em/112.gif'),
        alt: '[调皮]',
        name: '112.gif'
      },
      {
        url: require('@/assets/em/113.gif'),
        alt: '[笑]',
        name: '113.gif'
      },
      {
        url: require('@/assets/em/114.gif'),
        alt: '[惊讶]',
        name: '114.gif'
      },
      {
        url: require('@/assets/em/115.gif'),
        alt: '[难过]',
        name: '115.gif'
      },
      {
        url: require('@/assets/em/116.gif'),
        alt: '[酷]',
        name: '116.gif'
      },
      {
        url: require('@/assets/em/117.gif'),
        alt: '[汗]',
        name: '117.gif'
      },
      {
        url: require('@/assets/em/118.gif'),
        alt: '[抓狂]',
        name: '118.gif'
      },
      {
        url: require('@/assets/em/119.gif'),
        alt: '[吐]',
        name: '119.gif'
      },
      {
        url: require('@/assets/em/120.gif'),
        alt: '[笑]',
        name: '120.gif'
      },
      {
        url: require('@/assets/em/121.gif'),
        alt: '[快乐]',
        name: '121.gif'
      },
      {
        url: require('@/assets/em/122.gif'),
        alt: '[奇]',
        name: '122.gif'
      },
      {
        url: require('@/assets/em/123.gif'),
        alt: '[傲]',
        name: '123.gif'
      }
    ]
    const onlineEmoji = {
      '100.gif': 'AbNQgA.gif',
      '101.gif': 'AbN3ut.gif',
      '102.gif': 'AbNM3d.gif',
      '103.gif': 'AbN8DP.gif',
      '104.gif': 'AbNljI.gif',
      '105.gif': 'AbNtUS.gif',
      '106.gif': 'AbNGHf.gif',
      '107.gif': 'AbNYE8.gif',
      '108.gif': 'AbNaCQ.gif',
      '109.gif': 'AbNN4g.gif',
      '110.gif': 'AbN0vn.gif',
      '111.gif': 'AbNd3j.gif',
      '112.gif': 'AbNsbV.gif',
      '113.gif': 'AbNwgs.gif',
      '114.gif': 'AbNrD0.gif',
      '115.gif': 'AbNDuq.gif',
      '116.gif': 'AbNg5F.gif',
      '117.gif': 'AbN6ET.gif',
      '118.gif': 'AbNcUU.gif',
      '119.gif': 'AbNRC4.gif',
      '120.gif': 'AbNhvR.gif',
      '121.gif': 'AbNf29.gif',
      '122.gif': 'AbNW8J.gif',
      '123.gif': 'AbNob6.gif',
      '124.gif': 'AbN5K1.gif',
      '125.gif': 'AbNHUO.gif',
      '126.gif': 'AbNIDx.gif',
      '127.gif': 'AbN7VK.gif',
      '128.gif': 'AbNb5D.gif',
      '129.gif': 'AbNX2d.gif'
    }
    const handleSuccess = (resData) => {
      upload.value.clearFiles()
      console.log('上传成功返回...', resData)
      sendMsgContent.value = resData.msg
      onSubmit('img')
    }
    const handleFale = () => {
      upload.value.clearFiles()
    }
    const input = ref(null)
    const emjonShow = ref(false)
    const sendMsgContent = ref('')
    const send = ref(null)
    const msgList = [
      {
        type: 'user',
        msg: {
          id: 1,
          type: 'text',
          time: '12:56',
          userinfo: {
            uid: 0,
            username: '大黑哥',
            face: '@/assets/chat/face_13.jpg'
          },
          content: {
            text: '为什么温度会相差那么大'
          }
        }
      }
    ]
    const messageList = ref([])
    onMounted(() => {
      messageList.value = JSON.parse(localStorage.getItem('message'))
      setTimeout(() => {
        var div = document.getElementById('dialogue_box')
        div.scrollTop = div.scrollHeight
      }, 20)
    })
    const fun = (msg) => {
      console.log('我接受到了消息', msg)
      messageList.value = JSON.parse(localStorage.getItem('message'))
      const jsonMsg = JSON.parse(msg)
      console.log('jsonMsg', jsonMsg)
      messageList.value.push({
        type: 'user',
        msg: {
          id: 1,
          type: jsonMsg.chatMsg.type,
          time: '12:56',
          userinfo: {
            uid: 1,
            username: '大黑哥',
            face: '@/assets/chat/face_13.jpg'
          },
          content: {
            text: jsonMsg.chatMsg.msg.text,
            w: jsonMsg.chatMsg.imgMsg.w,
            h: jsonMsg.chatMsg.imgMsg.h,
            url: jsonMsg.chatMsg.imgMsg.url
          }
        }
      })
      localStorage.setItem('message', JSON.stringify(messageList.value))
      setTimeout(() => {
        var div = document.getElementById('dialogue_box')
        div.scrollTop = div.scrollHeight
      }, 20)
      // 需要对消息进行签收
    }
    //替换表情符号为图片
    const replaceEmoji = (str) => {
      console.log('替换', str)
      let replacedStr = str.replace(/\[([^(\]|\[)]*)\]/g, (item, index) => {
        console.log('item: ' + item)
        for (let i = 0; i < emojiList.length; i++) {
          let row = emojiList
          for (let j = 0; j < row.length; j++) {
            let EM = row[j]
            if (EM.alt == item) {
              //在线表情路径，图文混排必须使用网络路径，请上传一份表情到你的服务器后再替换此路径
              //比如你上传服务器后，你的100.gif路径为https://www.xxx.com/emoji/100.gif 则替换onlinePath填写为https://www.xxx.com/emoji/
              let onlinePath = 'https://s2.ax1x.com/2019/04/12/'
              let imgstr =
                '<img src="' + onlinePath + onlineEmoji[EM.name] + '">'
              console.log('imgstr: ' + imgstr)
              return imgstr
            }
          }
        }
      })
      return (
        '<div style="display: flex;align-items: center;word-wrap:break-word;">' +
        replacedStr +
        '</div>'
      )
      //return replacedStr;
    }
    const onSubmit = (type) => {
      if (sendMsgContent.value != '') {
        console.log('准备发送......', sendMsgContent.value)
        if (type === 'text') {
          sendMsgContent.value = replaceEmoji(sendMsgContent.value)
        }
        const content = {
          action: 2,
          chatMsg: {
            senderId: send.value.getUid(),
            type: type,
            receiverId: 'kefu',
            msg: {
              text: sendMsgContent.value
            },
            imgMsg: {
              url: sendMsgContent.value,
              w: 150,
              h: 150
            }
          }
        }
        // 进行消息的拼接，保存到浏览器缓存
        if (JSON.parse(localStorage.getItem('message'))) {
          messageList.value = JSON.parse(localStorage.getItem('message'))
          messageList.value.push({
            type: 'user',
            msg: {
              id: 1,
              type: type,
              time: '12:56',
              userinfo: {
                uid: 0,
                username: '大黑哥',
                face: '@/assets/chat/face_13.jpg'
              },
              content: {
                text: sendMsgContent.value,
                url: sendMsgContent.value,
                w: 150,
                h: 150
              }
            }
          })
        } else {
          messageList.value = [
            {
              type: 'user',
              msg: {
                id: 1,
                type: type,
                time: '12:56',
                userinfo: {
                  uid: 0,
                  username: '大黑哥',
                  face: '@/assets/chat/face_13.jpg'
                },
                content: {
                  text: sendMsgContent.value,
                  url: sendMsgContent.value,
                  w: 150,
                  h: 150
                }
              }
            }
          ]
        }
        localStorage.setItem('message', JSON.stringify(messageList.value))
        //this.msgList = messageList.value
        send.value.send(JSON.stringify(content))
        sendMsgContent.value = ''
        setTimeout(() => {
          var div = document.getElementById('dialogue_box')
          div.scrollTop = div.scrollHeight
        }, 20)
      }
    }
    const openOrClose = (msg) => {
      emjonShow.value = msg
    }
    //添加表情
    const addEmoji = (em) => {
      sendMsgContent.value += em.alt
      emjonShow.value = false
      document.getElementById('inputbox')?.focus()
    }
    const uploadImg = () => {}
    return {
      msgList,
      fun,
      sendMsgContent,
      onSubmit,
      send,
      messageList,
      emojiList,
      emjonShow,
      openOrClose,
      addEmoji,
      uploadImg,
      uploadUrl,
      upload,
      handleSuccess,
      handleFale,
      closeZaiXian
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.hello {
  .chat_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14.4rem;
    height: 20.32rem;
    background: #edeef0;
    border-radius: 0.43rem;
    .emjon {
      position: relative;
      .emjon_class {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        width: 5rem;
        background-color: rgb(255, 255, 255);
        bottom: 5.8rem;
      }
    }
    .top {
      width: 14.4rem;
      height: 2.16rem;
      background: #29c5ee;
      position: relative;
      border-top-left-radius: 0.43rem;
      border-top-right-radius: 0.43rem;
      .content {
        position: absolute;
        width: 2.56rem;
        height: 0.88rem;
        font-size: 0.64rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 0.88rem;
        top: 0.64rem;
        left: 0.53rem;
        background-color: #29c5ee;
      }
      .x {
        position: absolute;
        width: 0.75rem;
        height: 0.75rem;
        background: #29c5ee;
        top: 0.68rem;
        left: 13.12rem;
      }
    }
    .content {
      width: 14.4rem;
      height: 12.29rem;
      background-color: rgba(255, 255, 255, 0.725);
      overflow-y: auto;
      .row {
        position: relative;
        overflow: hidden;
        zoom: 1;
        margin-top: 0.4rem;
        margin-bottom: 0.1rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-size: 0.43rem;
        font-weight: 400;
        line-height: 0.59rem;
        //background-color: rgb(161, 108, 38);
        .my {
          color: #ffffff;
          .left {
            .my_span {
              max-width: 10rem;
              margin-right: 0.4rem;
              background: #29c5ee;
              width: auto;
              position: relative;
              border-radius: 0.2rem;
              padding: 0.2rem 0.3rem;
              float: right;
              box-sizing: border-box;
            }
            .left_img1 {
              margin-right: 0.4rem;
              position: relative;
              float: right;
            }
          }
        }
        .other {
          .left {
            .left_img {
              width: 1.6rem;
              height: 1.6rem;
              margin-left: 0.4rem;
            }
          }
          .right {
            .kefu {
              margin-left: 0.6rem;
              margin-top: -0.5rem;
            }
            .my_span {
              display: block;
              max-width: 10rem;
              background-color: rgb(255, 255, 255);
              width: auto;
              position: relative;
              margin-left: 2.3rem;
              border-radius: 0.2rem;
              padding: 0.2rem 0.3rem;
              margin-top: -1.4rem;
              float: left;
              box-sizing: border-box;
            }
          }
        }
      }
    }
    .bottom {
      box-sizing: border-box;
      width: 14.4rem;
      height: 5.87rem;
      background: #ffffff;
      position: relative;
      .input_class {
        outline: none;
        border: none;
        height: 0.6rem;
        width: 14rem;
        margin-top: 1.2rem;
        margin-left: 0.5rem;
      }
      .tupian {
        width: 0.53rem;
        height: 0.53rem;
        position: absolute;
        left: 1.6rem;
        top: 0.32rem;
      }
      .biaoqing {
        width: 0.53rem;
        height: 0.53rem;
        position: absolute;
        left: 0.53rem;
        top: 0.32rem;
      }
      .fasong {
        position: absolute;
        right: 0.53rem;
        bottom: 0.53rem;
        background: #29c5ee;
        border-radius: 0.21rem;
        width: 2.67rem;
        height: 1.07rem;
        padding-left: 0.51rem;
        padding-top: 0.25rem;
        font-size: 0.43rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 0.59rem;
        cursor: pointer;
        .fasong_img {
          width: 0.64rem;
          height: 0.58rem;
        }
        .fasong_wenzi{
          display: block;
          margin-top: -0.7rem;
          margin-left: 0.73rem;
        }
      }
    }
  }
}
input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgb(190, 198, 198);
}

input:-moz-placeholder {
  /* Firefox 18- */
  color: rgb(190, 198, 198);
  opacity: 1;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: rgb(190, 198, 198);
  opacity: 1;
}
</style>
