<template>
  <div class="page flex-col">
    <ClickUser v-if="clickUserShow" v-on:success="showyuyueForm1" />
    <div class="group_1 flex-row">
      <img
        class="image_1"
        referrerpolicy="no-referrer"
        src="../home/assets/img/logo_blue.png"
      />
      <span style="cursor: pointer" class="text_1"><router-link style="color:black" to="/">巧鲸云IP副驾驶</router-link></span>
      <span style="cursor: pointer;margin: 0.614rem 0 0 1.72rem;" @click="showyuyueForm1" class="text_2"><router-link to="/mail" style="color: #1c1d1d;">巧鲸云邮件分发助手</router-link></span>
        <!-- <div class="text-wrapper_1 flex-col">
          <span style="cursor: pointer" class="text_1">巧鲸云IP副驾驶</span>
        </div> -->
        <!-- <span style="cursor: pointer;margin: 0.614rem 0 0 3.72rem;" class="text_2" >新闻动态</span> -->
        <div class="text-wrapper_1 flex-col" style="cursor: pointer;">
          <span  class="text_2">新闻动态</span>
        </div>
      <span style="cursor: pointer;margin: 0.614rem 0 0 1.12rem;" class="text_2" ><router-link to="/about" style="color:black">关于我们</router-link></span>
      <span style="cursor: pointer;margin: 0.614rem 0 0 1.82rem;" @click="showyuyueForm1" class="text_3">点击试用</span>
    </div>
    <div class="group_2 flex-col">
      <div class="text-wrapper_1">
        <span class="text_4">巧鲸云IP副驾驶&nbsp;&gt;</span>
        <span class="text_5">新闻详情</span>
      </div>
      <div class="content">
        <div class="left">
          <div class="top">新闻列表</div>
          <div
            v-for="(item, index) in showLeft"
            :key="index"
            class="content_list"
            @click="showDetails(item)"
          >
            <img :src="item.img" class="content_img" />
            <div
              :class="{
                content_title: true,
                content_title1: content_title_index === item.id
              }"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="left_bottom">
            <span
              @click="showNextPre(0)"
              style="cursor: pointer"
              :class="{ left_span: true, left_span1: showPage === 1 }"
              >上一页</span
            >
            <span class="left_span">{{ showPage }}/{{ showPages }}</span>
            <span
              @click="showNextPre(1)"
              style="cursor: pointer"
              :class="{ left_span: true, left_span1: showPage === showPages }"
              >下一页</span
            >
          </div>
        </div>
        <div class="right">
          <h1>{{ showItem.title }}</h1>
          <span class="auto_time"
            >原创：{{
              showItem.author
            }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
          >
          <span class="auto_time"
            >发表时间：{{ showItem.createTimeString }}</span
          >
          <div class="right_content" v-html="showItem.url"></div>
        </div>
      </div>
      <Bottom />
    </div>
  </div>
</template>
<script>
import Bottom from '../bottom/index.vue'
import { ref, onMounted, onBeforeMount, onBeforeUnmount } from 'vue'
import { post, get } from '@/utils/request'
import { useRouter, useRoute } from 'vue-router'
import ClickUser from '@/components/ClickUser.vue'
export default {
  components: { Bottom, ClickUser },
  setup(props) {
    onBeforeMount(() => {
      document.getElementsByTagName('body')[0].style['font-size'] = '16px'
    })
    onBeforeUnmount(() => {
      document.getElementsByTagName('body')[0].style['font-size'] = '0.12rem'
    })
    onMounted(async () => {
      const homeList = await get('/admin/news/list', {
        currentPage: showPage.value,
        pageSize: 5
      })
      console.log(homeList,'homeList');
      if (homeList.code === 0) {
        showPage.value = homeList.data.pageNum
        showPages.value = homeList.data.pages
        showLeft.value = homeList.data.list
      }
      const itemFrom = localStorage.getItem('item')
      showItem.value = JSON.parse(itemFrom)
      content_title_index.value = JSON.parse(itemFrom).id
    })
    const router = useRouter()
    const route = useRoute()
    const showPage = ref(1)
    const showPages = ref(1)
    const showLeft = ref([])
    const clickUserShow = ref(false)
    const showItem = ref({})
    const showyuyueForm1 = () => {
      if (clickUserShow.value === true) {
        clickUserShow.value = false
      } else {
        clickUserShow.value = true
      }
    }
    const content_title_index = ref(0)
    const showNextPre = async (num) => {
      if (num === 1) {
        if (showPage.value === showPages.value) {
          return
        }
        const homeList = await get('/admin/news/list', {
          currentPage: showPage.value + 1,
          pageSize: 5
        })
        console.log(homeList);
        if (homeList.code === 0) {
          showPage.value = homeList.data.pageNum
          showPages.value = homeList.data.pages
          showLeft.value = homeList.data.list
        }
      } else {
        if (showPage.value === 1) {
          return
        }
        const homeList = await get('/admin/news/list', {
          currentPage: showPage.value - 1,
          pageSize: 5
        })
        if (homeList.code === 0) {
          showPage.value = homeList.data.pageNum
          showPages.value = homeList.data.pages
          showLeft.value = homeList.data.list
        }
      }
    }
    const showDetails = async (item) => {
      content_title_index.value = item.id
      showItem.value = item
      await get('/admin/view/add', {
        addOne: 'news_view'
      })
    }
    return {
      showPage,
      showPages,
      showNextPre,
      showLeft,
      showDetails,
      showItem,
      content_title_index,
      showyuyueForm1,
      clickUserShow
    }
  }
}
</script>
<style scoped lang="scss" src="./assets/index.rem.scss" />
<style lang="scss">
  .right_content img{
    max-width: 100%;
  }
</style>

