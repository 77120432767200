<template></template>

<script>
import { baseUrl, basews } from '@/utils/request'
export default {
  props: {
    fun: Function
  },
  data() {
    return {
      path: basews,
      socket: ''
    }
  },
  mounted() {
    // 初始化
    this.init()
  },
  methods: {
    init: function () {
      if (typeof WebSocket === 'undefined') {
        alert('您的浏览器不支持socket')
      } else {
        let protocol = document.location.protocol == 'https:' ? 'wss://' : 'ws://'
        let abs_path = `${protocol}${document.location.host}${this.path}`

        console.log(`ws path4:${abs_path}`)
        // 实例化socket
        this.socket = new WebSocket(abs_path)
        // 监听socket连接
        this.socket.onopen = this.open
        // 监听socket错误信息
        this.socket.onerror = this.error
        // 监听socket消息
        this.socket.onmessage = this.getMessage
      }
    },
    open: function () {
      console.log('socket连接成功')
      const firstSend = {
        action: 1,
        chatMsg: {
          senderId: this.getUid()
        }
      }
      console.log(JSON.stringify(firstSend))
      this.send(JSON.stringify(firstSend))
    },
    error: function () {
      console.log('连接错误')
    },
    getMessage: function (msg) {
      this.fun(msg.data)
    },
    send: function (sendMsg) {
      this.socket.send(sendMsg)
    },
    close: function () {
      console.log('socket已经关闭')
    },
    getUid: function () {
      if (localStorage.getItem('userId')) {
        return localStorage.getItem('userId')
      } else {
        localStorage.setItem('userId', this.getNowTimeRadom())
        return localStorage.getItem('userId')
      }
    },
    getNowTimeRadom: function () {
      var date = new Date()
      var yy = date.getFullYear()
      var MM = date.getMonth() + 1
      //使用三目运算符，判断是一位还是两位，不足补0，最后返回拼接字符串日期时间用-分割，毫秒使用.分割
      MM = MM < 10 ? '0' + MM : MM
      var dd = date.getDate()
      dd = dd < 10 ? '0' + dd : dd
      var HH = date.getHours()
      HH = HH < 10 ? '0' + HH : HH
      var mm = date.getMinutes()
      mm = mm < 10 ? '0' + mm : mm
      var ss = date.getSeconds()
      ss = ss < 10 ? '0' + ss : ss
      var ms = date.getMilliseconds()
      ms = ms < 100 ? '0' + ms : ms
      ms = ms < 10 ? '0' + ms : ms
      var code = ''
      //设置长度，这里看需求，我这里设置了4
      var codeLength = 4

      //设置随机字符
      var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9)

      //循环codeLength 我设置的4就是循环4次
      for (var i = 0; i < codeLength; i++) {
        //设置随机数范围,这设置为0 ~ 36
        var index = Math.floor(Math.random() * 9)

        //字符串拼接 将每次随机的字符 进行拼接
        code += random[index]
      }
      return (
        yy + '' + MM + '' + dd + '-' + HH + '' + mm + '' + ss + '.' + ms + code
      )
    }
  },
  destroyed() {
    // 销毁监听
    this.socket.onclose = this.close
  }
}
</script>