import axios from "axios";
// export const baseUrl = 'http://43.138.111.70:9633'
// export const basews = 'ws://43.138.111.70:8088/ws'
export const baseUrl = '/qb/'
export const basews = '/ws' //'ws://lu:8030/ws'  //  //'ws://127.0.0.1:8088/ws'
const instance = axios.create({
    baseURL: baseUrl,
    //baseURL: 'http://43.138.111.70:9633',
    timeout: 30000,
    headers: { 'token': '123456' }
})

export const post = (url, data = {}) => {
    return new Promise((resolve, reject) => {
        instance.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            resolve(response.data)
        }), err => {
            reject(err)
        }
    })
}

export const get = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        instance.get(url, { params }).then((response) => {
            resolve(response.data)
        }), err => {
            reject(err)
        }
    })
}
