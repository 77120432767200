<template>
  <router-view />
</template>
<script>
window.addEventListener('resize', () => {
  //浏览器变化执行动作
  // 手机的宽度
  var width = document.documentElement.clientWidth || document.body.clientWidth
  console.log(width)
  // 因为项目开发采用的电脑宽度是375  1rem = 71px
  var radio = width / 1920
  // 新的设备的font-size
  var fontSize = 37.5 * radio
  // 这个项目定义了html的font-size的大小是100，然后rem是根据像素的大小来定位的
  document.getElementsByTagName('html')[0].style['font-size'] = fontSize + 'px'
})
</script>

<style lang="scss" src="./assets/common.scss">
</style>
