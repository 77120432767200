<template>
  <div class="page flex-col">
    <ClickUser v-if="clickUserShow" v-on:success="showyuyueForm1" />
    <div class="box_16 flex-col">
      <div class="section_3 flex-col">
        <div class="image-wrapper_2 flex-row justify-between">
          <img
            class="image_10"
            referrerpolicy="no-referrer"
            src="../home/assets/img/logo_bai.png"
          />
        </div>
        <div class="text-wrapper_8 flex-row">
          <span class="text_29">企业介绍</span>
          <span class="text_30">联系我们</span>
          <span class="text_31">友情链接</span>
        </div>
        <div class="group_5 flex-row">
          <div class="text-wrapper_9 flex-col justify-between">
             <el-link :underline="false"  @click="jump('job','about')">
              <span class="text_32">关于我们</span>
             </el-link>
             <el-link :underline="false"  @click="jump('job','join')">
              <span class="text_33">加入我们</span>
             </el-link>
             <el-link :underline="false"   @click="jump('news')">
              <span class="text_34">新闻动态</span>
             </el-link>
          </div>
          <div class="text-wrapper_10 flex-col justify-between">
            <span class="text_35 text_color">北京万合之众科技有限公司</span>
            <span class="text_37 text_color">电话：18513099002</span>
            <span class="text_38 text_color">邮箱：vip@linkyoon.com</span>
            <span class="text_36 text_color">地址：北京市海淀区中关村东路8号东升大厦A座4层</span>
          </div>
          <div class="text-wrapper_11 flex-col justify-between">
            <span class="text_39 text_color"><a href="https://sbj.cnipa.gov.cn/sbj/index.html" target="_blank">国家知识产权局商标局</a></span>
            <span class="text_40 text_color"><a hrf="https://www.cnipa.gov.cn/" target="_blank">国家知识产权局</a></span>
            <span class="text_41 text_color"><a href="https://www.wipo.int/portal/zh/" target="_blank">世界知识产权组织</a></span>
          </div>
          <div class="image-text_10 flex-col justify-between">
            <div class="image-wrapper_3 flex-col">
              <img
                class="image_12"
                referrerpolicy="no-referrer"
                src="../home/assets/img/SketchPng5bef3c4372562a231557c37a3d32293a6989e67078c7f043e36549fa0ba3ad0d.png"
              />
            </div>
            <span class="text-group_10 text_color">扫码关注更多资讯</span>
          </div>
        </div>
        <img
          class="image_13"
          referrerpolicy="no-referrer"
          src="../home/assets/img/SketchPng12bbb339aec946fe4373ee8a4d64cf700f2cfd3276653e5a2950550bb41d16ec.png"
        />
        <span class="text_42 text_color"
          >Copyright&nbsp;©&nbsp;2021-2023&nbsp;linkyoon.com版权所有</span
        >
        <span class="text_43 text_color"
          >网站备案号：京ICP备2022023898号-2</span
        >
      </div>
      <div v-if="showZaixiankefu" class="zaixiankefu">
        <Chat :closeZai="closeZai" />
      </div>
      <div class="section_4 flex-col">
        <!-- <div class="box_17 flex-row">
          <div
            @click="showkefu"
            style="cursor: pointer"
            class="image-text_11 flex-col justify-between"
          >
            <img
              class="label_7"
              referrerpolicy="no-referrer"
              src="../home/assets/img/SketchPng9c9ed7ed65d64fc612f44daa4e263c8743c99ffa1bf2635c864eca0d77ee0cc4.png"
            />
            <span class="text-group_11">在线客服</span>
          </div>
        </div>
        <img
          class="image_14"
          referrerpolicy="no-referrer"
          src="../home/assets/img/SketchPng791054ec9c204f125027faeb5c59f9ac7d4eff90b8751d187a1d63c3ecc4487f.png"
        /> -->
        <img
          class="label_8"
          referrerpolicy="no-referrer"
          src="../home/assets/img/SketchPngba7525fc2c8ce08ebe47ea611b23c1d67b56d67049b158c0f2362a51cf362152.png"
          style="cursor: pointer"
          @click="showyuyueForm1"
        />
        <span @click="showyuyueForm1" class="text_44" style="cursor: pointer"
          >点击试用</span
        >
        <img
          class="image_15"
          referrerpolicy="no-referrer"
          src="../home/assets/img/SketchPng791054ec9c204f125027faeb5c59f9ac7d4eff90b8751d187a1d63c3ecc4487f.png"
        />
        <div
          @click="toTop"
          style="cursor: pointer"
          class="image-text_12 flex-col justify-between"
        >
          <img
            class="image_16"
            referrerpolicy="no-referrer"
            src="../home/assets/img/SketchPng3db2076e95f80151e6be0385a2529426c2e9615451c39014887559d3e40db849.png"
          />
          <span class="text-group_12">返回顶部</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, onBeforeMount } from 'vue'
import Chat from '../../chat/Chat.vue'
import ClickUser from '@/components/ClickUser.vue'
export default {
  components: { Chat, ClickUser },
  setup(props) {
    const constants = {}
    const showYouShi = ref(1)
    const showZaixiankefu = ref(false)
    const clickUserShow = ref(false)
    const showyuyueForm1 = () => {
      if (clickUserShow.value === true) {
        clickUserShow.value = false
      } else {
        clickUserShow.value = true
      }
    }
    const loopData0 = ref([
      {
        lanhuBg2:
          'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/a1c5325b417e42e6ab7c7c365b549198_mergeImage.png)',
        lanhutext0: '招商基金',
        lanhutext1:
          '作为中国知名的基金管理公司，招商基金拥有庞大的产品体系和用户群体，公司目前管理着…',
        lanhutext2: '查看详情',
        lanhuimage0:
          'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc3698057f2f2e50c69cbccc2521e2651b4ff4453e69473a70cd181ffdb07bd67'
      },
      {
        lanhuBg2:
          'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/f75dd444eb254efc8b09e9da9033ae60_mergeImage.png)',
        lanhutext0: '新时代文档系统解决方案',
        lanhutext1:
          '作为中国知名的基金管理公司，招商基金拥有庞大的产品体系和用户群体，公司目前管理着…',
        lanhutext2: '查看详情',
        lanhuimage0:
          'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc3698057f2f2e50c69cbccc2521e2651b4ff4453e69473a70cd181ffdb07bd67'
      },
      {
        lanhuBg2:
          'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/2b528d95f7be4f7b8646c6c77b8b1cb1_mergeImage.png)',
        lanhutext0: '为客户提供定制化的咨询服务…',
        lanhutext1:
          '作为中国知名的基金管理公司，招商基金拥有庞大的产品体系和用户群体，公司目前管理着…',
        lanhutext2: '查看详情',
        lanhuimage0:
          'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc3698057f2f2e50c69cbccc2521e2651b4ff4453e69473a70cd181ffdb07bd67'
      }
    ])
    const youshiClick = (msg) => {
      showYouShi.value = msg
    }
    const toTop = () => {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
    const showkefu = () => {
      if (showZaixiankefu.value) {
        showZaixiankefu.value = false
      } else {
        showZaixiankefu.value = true
      }
    }
    const closeZai = () => {
      showZaixiankefu.value = false
    }
    return {
      loopData0,
      showYouShi,
      constants,
      youshiClick,
      toTop,
      showkefu,
      showZaixiankefu,
      closeZai,
      showyuyueForm1,
      clickUserShow,
    }
  },
  methods:{
    jump(name,id){
      if (this.$route.name == name) {
        let element = document.getElementById(id);
        element.scrollIntoView(true);
        return 
      }
      if (id) {
        this.$router.push({name,query:{id}});  
      }else{
        this.$router.push({name}); 
      }

    }

  }
}
</script>
<style scoped lang="scss" src="./assets/index.rem.scss" />