<template>
  <div class="page flex-col" id="about">
    <ClickUser v-if="clickUserShow" v-on:success="showyuyueForm1" />
    <div class="block_1 flex-col">
      <div class="group_111 flex-row">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="../home/assets/img/logo_bai.png"
        />
        <div class="text-wrapper_1 flex-col">
          <span style="cursor: pointer" class="text_1"><router-link to="/">巧鲸云IP副驾驶</router-link></span>
        </div>
        <span style="cursor: pointer"  class="text_2"
          ><router-link to="/mail">巧鲸云邮件分发助手</router-link></span
        >
        <span style="cursor: pointer;margin: 0.614rem 0 0 3.42rem;" class="text_2"
        @click="newsMore"
          >新闻动态</span
        >
        <div class="text-wrapper_22 flex-col">
          <span style="cursor: pointer" class="text_2">关于我们</span>
        </div>
        <span style="cursor: pointer" @click="showyuyueForm1" class="text_3"
          >点击试用</span
        >
      </div>
      <div class="text-wrapper_2 flex-row">
        <span class="text_4">关于我们</span>
      </div>
      <div class="text-wrapper_3 flex-row">
        <span class="text_5"
          >北京万合之众科技有限公司自主研发的商标档案管理系统</span
        >
      </div>
    </div>
    <div class="block_2 flex-col">
      <div class="text-wrapper_4 flex-col">
        <span class="text_6">关于我们</span>
        <span class="paragraph_1">
北京万合之众科技有限公司是一家聚焦知识产权数据应用与管理的软件公司，总部位于北京。万合之众顺应全球数字化经济发展形势，倾力打造“巧鲸云”数智化管理平台，为企业和知识产权服务机构提供集开发、咨询、维护于一体的IT解决方案。巧鲸云将始终秉承着专业、创新和服务的核心理念，积极致力于促进新时代数字化与专业化的融合,提升企业及服务机构的工作效率和创新性，以数智化赋能助力法律行业数字化转型发展!
          <br />
        </span>
      </div>
      <div class="group_1 flex-col" id="join">
        <div class="text-wrapper_5 flex-row">
          <span class="text_7">加入我们</span>
        </div>
        <div class="text-wrapper_6 flex-row justify-between">
          <span class="text_8">简历投递（请备注应聘岗位）：</span>
          <span class="text_9">hr&#64;linkyoon.com</span>
        </div>
        <div class="text-wrapper_7 flex-row">
          <span class="text_10">期待优秀的你加入！</span>
        </div>
      </div>
      <div class="group_2 flex-row justify-between">
        <div class="grid_1 flex-row">
          <div
            class="list-items_1 flex-col"
            v-for="(item, index) in loopData0"
            :key="index"
          >
            <div class="backColor"></div>
            <div class="text-wrapper_8 flex-row justify-between">
              <span class="text_11" v-html="item.title"></span>
              <span class="text_12">{{ item.jnumber }}名</span>
            </div>
            <span class="text_13">岗位职责:</span>
            <span class="text_14" style="white-space: pre-wrap">{{
              item.content
            }}</span
            ><br />
            <span class="text_15">任职要求：</span>
            <span class="text_16" style="white-space: pre-wrap">{{
              item.technique
            }}</span>
          </div>
        </div>
      </div>
      <div v-if="loopData0.length > 2" class="group_3 flex-row">
        <div
          @click="hiddenMore"
          style="cursor: pointer"
          class="image-text_3 flex-row justify-between"
        >
          <span class="text-group_3">收起岗位</span>
          <img
            class="thumbnail_1"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPnga1f2a244bf4132e1e570a99a5a2d0d66e60ea17f702b21a5ca488806e6a8661c.png"
          />
        </div>
      </div>
      <div v-else class="group_3 flex-row">
        <div
          @click="showMore"
          class="image-text_3 flex-row justify-between"
          style="cursor: pointer"
        >
          <span class="text-group_3" style="margin-left: -0.5rem"
            >查看更多岗位</span
          >
          <img
            class="thumbnail_1"
            referrerpolicy="no-referrer"
            src="./assets/img/icon_zhankai.png"
            style="margin-left: 1.3rem"
          />
        </div>
      </div>
      <Bottom />
    </div>
  </div>
</template>
<script>
import Bottom from '../bottom/index.vue'
import { ref, onMounted, onBeforeMount, onBeforeUnmount } from 'vue'
import { post, get } from '@/utils/request'
import { useRouter, useRoute } from 'vue-router'
import ClickUser from '@/components/ClickUser.vue'
export default {
  components: { Bottom, ClickUser },
  setup() {
    const  route = useRoute()
    const router = useRouter()
    onMounted(async () => {
      if (route.query.id) {
        var element = document.getElementById(route.query.id);
          element.scrollIntoView(true);
      }
      const homeList = await get('/admin/job-info/list', {
        currentPage: 1,
        pageSize: 2
      })
      if (homeList.code === 0) {
        loopData0.value = homeList.data.list
      }
    })
    const clickUserShow = ref(false)
    const showyuyueForm1 = () => {
      if (clickUserShow.value === true) {
        clickUserShow.value = false
      } else {
        clickUserShow.value = true
      }
    }
    const showMore = async () => {
      await get('/admin/view/add', {
        addOne: 'jpb_view'
      })
      const homeList = await get('/admin/job-info/list', {
        currentPage: 1,
        pageSize: 200
      })
      if (homeList.code === 0) {
        loopData0.value = homeList.data.list
      }
    }
    const hiddenMore = () => {
      while (loopData0.value.length > 2) {
        loopData0.value.pop()
      }
    }
    const loopData0 = ref([
      // {
      //   lanhutext0: '销售经理',
      //   lanhutext1: '2名',
      //   lanhutext2: '岗位职责：',
      //   lanhutext3:
      //     '3、负责销售团队日常管理、监督、指导、培训和工作评估；<br/>4、负责完成公司及上级交办的其他工作。',
      //   lanhutext4: '任职要求：',
      //   lanhutext5:
      //     '1、全日制大专及以上学历；<br/>2、2年以上toB行业团队管理经验；<br/>3、有saas软件，互联网等行业客户销售经验优先；<br/>4、积极进取、学习能力强，目标感强；<br/>5、具有较强的沟通能力和抗压能力。'
      // }
    ])
    const newsMore = () => {
      router.push(`/news?item=1`)
      localStorage.setItem('item', JSON.stringify(loopData0.value[0]))
    }
    return { loopData0, showMore, hiddenMore, showyuyueForm1, clickUserShow,newsMore }
  }
}
</script>
<style scoped lang="scss" src="./assets/index.rem.scss" />