<template>
  <div>
    <div v-if="showSubmitSuccess" class="toastBackground">
      <div class="showSubmitSuccess">
        <span class="success_message"
          >我们已收到您的申请，我们会尽快联系您。再次感谢您的关注。</span
        ><br />
        <el-button
          style="margin-top: 20px"
          class="el_button_class"
          type="primary"
          @click="submitshowSubmitSuccess"
          >确定</el-button
        >
      </div>
    </div>
    <div v-else class="toastBackground">
      <div class="shiyongbiaodan">
        <div class="yuyueyanshi">
          试用申请
          <img
            @click="closeForm"
            class="icon_guanbitanchuang"
            src="@/views/new/home/assets/img/icon_guanbitanchuang.png"
          />
        </div>
        <el-form
          :model="dynamicValidateForm"
          label-width="200px"
          class="demo-dynamic"
          ref="dynamicValidateFormRef"
        >
          <el-form-item
            prop="name"
            label="您的称呼(已加密)："
            :rules="[
              { required: true, message: '请输入称呼', trigger: 'blur' }
            ]"
          >
            <el-input v-model="dynamicValidateForm.name"></el-input>
          </el-form-item>
          <el-form-item
            prop="company_name"
            label="您的公司全称(已加密)："
            :rules="[
              { required: true, message: '请输入公司全称', trigger: 'blur' }
            ]"
          >
            <el-input v-model="dynamicValidateForm.company_name"></el-input>
          </el-form-item>
          <el-form-item
            prop="email"
            label="邮箱(已加密)："
            :rules="[
              { required: false, message: '请输入邮箱', trigger: 'blur' }
            ]"
          >
            <el-input v-model="dynamicValidateForm.email"></el-input>
          </el-form-item>
          <el-form-item
            prop="phone"
            label="手机号码(已加密)："
            :rules="[
              { required: true, message: '请输入手机号码', trigger: 'blur' },
              { min: 11, max: 11, message: '长度必须是11位', trigger: 'blur' }
            ]"
          >
            <el-input v-model="dynamicValidateForm.phone"></el-input>
          </el-form-item>
          <el-form-item
            prop="code"
            label="短信验证码："
            :rules="[
              { required: true, message: '请输入短信验证码', trigger: 'blur' }
            ]"
          >
            <el-input
              style="width: 150px"
              v-model="dynamicValidateForm.code"
            ></el-input>
            <el-button
              class="el_button_class"
              style="margin-left: 10px"
              size="small"
              type="primary"
              @click="sendCode"
              >{{ showCode }}</el-button
            >
          </el-form-item>
          <div v-if="phoneError" style="margin-left: 193px; color: red">
            手机号或验证码错误
          </div>
          <el-form-item>
            <el-button
              style="margin-top: 10px"
              class="el_button_class"
              type="primary"
              @click="submitForm('dynamicValidateFormRef')"
              >立即申请</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { post, get } from '@/utils/request'
export default {
  components: {},
  props: {},
  setup(props, context) {
    const showSubmit = ref(true)
    const constants = {}
    const showYouShi = ref(1)
    const showZaixiankefu = ref(false)
    const showCode = ref('短信验证码')
    const dynamicValidateFormRef = ref(null)
    const phoneError = ref(false)
    const companyJobShow = ref(false)
    const showSubmitSuccess = ref(false)
    const dynamicValidateForm = ref({
      name: undefined,
      company_name: undefined,
      company_job: undefined,
      phone: undefined,
      email: undefined,
      code: undefined
    })
    const loopData0 = ref([])
    const youshiClick = (msg) => {
      showYouShi.value = msg
    }
    const toTop = () => {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
    const showkefu = () => {
      if (showZaixiankefu.value) {
        showZaixiankefu.value = false
      } else {
        showZaixiankefu.value = true
      }
    }
    const closeZai = () => {
      showZaixiankefu.value = false
    }
    const submitForm = (formName) => {
      dynamicValidateFormRef.value.validate(async (valid) => {
        if (valid) {
          const resData = await post('/apply/form', {
            phone: dynamicValidateForm.value.phone,
            name: dynamicValidateForm.value.name,
            companyName: dynamicValidateForm.value.company_name,
            companyJob: dynamicValidateForm.value.company_job,
            email: dynamicValidateForm.value.email,
            code: dynamicValidateForm.value.code,
            type: 'S'
          })
          if (resData.code != 0) {
            phoneError.value = true
          } else {
            showSubmitSuccess.value = true
            showSubmit.value = false
            dynamicValidateForm.value.phone = undefined
            dynamicValidateForm.value.name = undefined
            dynamicValidateForm.value.company_name = undefined
            dynamicValidateForm.value.company_job = undefined
            dynamicValidateForm.value.email = undefined
            dynamicValidateForm.value.code = undefined
            phoneError.value = false
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
    const closeForm = () => {
      dynamicValidateForm.value.phone = undefined
      dynamicValidateForm.value.name = undefined
      dynamicValidateForm.value.company_name = undefined
      dynamicValidateForm.value.company_job = undefined
      dynamicValidateForm.value.email = undefined
      dynamicValidateForm.value.code = undefined
      showSubmit.value = false
      phoneError.value = false
      context.emit('success')
    }
    const submitshowSubmitSuccess = ()=>{
      showSubmitSuccess.value = false
      context.emit('success')
    }
    const resetForm = (formName) => {
      this.$refs[formName].resetFields()
    }
    const showyuyueForm = () => {
      showSubmit.value = true
      phoneError.value = false
    }
    const removeDomain = (item) => {
      var index = this.dynamicValidateForm.domains.indexOf(item)
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1)
      }
    }
    const addDomain = () => {
      this.dynamicValidateForm.domains.push({
        value: '',
        key: Date.now()
      })
    }
    const checknumber = (String) => {
      var reg = /^\d+$/
      if (reg.test(String)) {
        return true
      }
      return false
    }
    const sendCode = async () => {
      if (checknumber(dynamicValidateForm.value.phone) != true) {
        return
      }
      if(dynamicValidateForm.value.phone.length != 11){
        return
      }
      if (showCode.value != '短信验证码') {
        return
      }
      let i = 60
      const interval = setInterval(() => {
        showCode.value = i + 's'
        i = i - 1
        if (i == 0) {
          showCode.value = '短信验证码'
          clearInterval(interval)
        }
      }, 1000)
      const resData = await get('/apply/send', {
        phone: dynamicValidateForm.value.phone
      })
      if (resData.code === 0) {
      }
    }
    return {
      loopData0,
      showYouShi,
      constants,
      youshiClick,
      toTop,
      showkefu,
      showZaixiankefu,
      closeZai,
      dynamicValidateForm,
      submitForm,
      resetForm,
      removeDomain,
      addDomain,
      showCode,
      sendCode,
      dynamicValidateFormRef,
      showSubmit,
      phoneError,
      companyJobShow,
      closeForm,
      showyuyueForm,
      showSubmitSuccess,
      submitshowSubmitSuccess
    }
  }
}
</script>

<style lang="scss" scoped>
.toastBackground {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  top: 0;
  right: 0;
  z-index: 2000;
  position: fixed;

  .showSubmitSuccess{
      width: 400px;
      height: 140px;
      background: #FFFFFF;
      border-radius: 0.43rem;
      top: 50%;
      left: 50%;
      z-index: 2000;
      transform: translate(-50%, -50%);
      position: fixed;
      padding: 15px 20px;
      .success_message{
        font-size: 18px;
      }
      .el_button_class {
        background-color: #29C5EE;
        margin-left: 140px;
      }
    }

  .shiyongbiaodan {
    width: 518px;
    height: 593px;
    background: #ffffff;
    border-radius: 0.43rem;
    top: 50%;
    left: 50%;
    z-index: 2000;
    transform: translate(-50%, -50%);
    position: fixed;

    .el_button_class {
      background-color: #29c5ee;
    }

    .demo-dynamic {
      .el-form-item {
        margin-left: 0px;
      }

      .el-input {
        width: 250px;
        height: 35px;
        margin-top: 10px;
      }

      ::v-deep .el-form-item__label {
        transform: translateY(10px);
      }

      ::v-deep .el-form-item__error {
        transform: translateX(200px);
      }
    }

    .yuyueyanshi {
      width: 126px;
      height: 29px;
      font-size: 21px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #29c5ee;
      line-height: 29px;
      margin-left: 210px;
      margin-top: 21px;
      margin-bottom: 30px;
      position: relative;
      .icon_guanbitanchuang {
        position: absolute;
        right: -171px;
        margin-top: -10px;
      }
    }
  }
}
</style>